<template>
  <el-container class="admin-index-main">
    <el-main>
      <std-nav :paths="['系统管理']"></std-nav>
      <el-card v-for="group in FuncButtons" :key="group.name">
        <template #header>{{group.text}}</template>
        <el-row :gutter="10">
          <el-col :span="4" v-for="(btn,btn_index) in group.btns" :key="btn_index">
            <div>
              <div class="btn" @click="onShowPage(btn)">
                <i :class="`el-icon-${btn.icon}`"></i>
                <span v-text="btn.text"></span>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-card>
    </el-main>
    <el-aside>
      <el-card class="homepage-user">
        <el-avatar style="vertical-align:top;" src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"></el-avatar>
        <div class="acc">
          <div class="uname">{{UserData.user_name}}</div>
          <div><span class="label">用户ID:</span>{{UserData.user_id}}</div>
          <div><span class="label">登录方式:</span>{{UserData.user_type_name}}</div>
        </div>
      </el-card>
      <el-card class="notifybox list" style="min-height:200px;">
        <template #header>
          系统通知
          <el-link type="primary" style="float:right">查看全部</el-link>
        </template>
        <div class="main">
          <el-link>秘钥xxxx即将失效</el-link>
          <el-link>您的密码即将过期,请及时更新密码</el-link>
        </div>
      </el-card>
      <el-card class="latelybox list">
        <template #header>
          最近访问
        </template>
        <div class="main">
          <el-link>电子券支付接口V1</el-link>
          <el-link>微信前端接口文档</el-link>
          <el-link>物品库存管理2020-05-11</el-link>
        </div>
      </el-card>
    </el-aside>
  </el-container>
</template>
<script>
export default {
  data() {
    return {

    }
  },
  computed: {
    UserData() {
      return this.$store.state.global.LoginInfo
    },
    FuncButtons() {
      return [
        {
          text: "用户信息管理",
          name: "user",
          btns: [
            { text: "用户管理", icon: "s-custom", url: '/UserMgr/Index' },
            { text: "权限设定", icon: "bangzhu", url: '/AuthMgr/Index' },
            { text: '授权管理', icon: 'pie-chart', url: '/TokenMgr/Index' },
            // { text: "菜单管理", icon: "s-operation", url: '/AdmMenu/Index' },
          ]
        },
        {
          text: "应用管理",
          name: "app",
          btns: [
            { text: "应用列表", icon: "cpu", url: '/AppMgr/applist' },
            { text: "应用新增", icon: 'download', url: '/AppMgr/Create' },
            { text: '应用分组', icon: 's-cooperation', url: '/AppMgr/groups' },
            { text: '系统权限设置', icon: 's-tools', url: '/AppMgr/SysAuth' },
            { text: "应用管理日志", icon: "tickets",url:'/AppMgr/ActionLog' }
          ]
        },
        {
          text: "系统工具",
          name: "tools",
          btns: [
            { text: "接口日志", icon: "tickets" },
          ]
        }
      ]
    }
  },
  methods: {
    onShowPage(config) {
      this.$router.push({ path: config.url })
    }
  }
}
</script>
<style lang="scss">
.admin-index-main {
  width: 1200px;
  margin: 0 auto;
  > .el-main {
    padding: 0px 20px 10px 0px !important;

    .btn {
      text-align: center;
      cursor: pointer;
      width: 100px;
      margin: 0 auto;
      &:hover,
      &:active {
        color: #66b1ff;
      }
      i {
        display: block;
        font-size: 40px;
      }
      span {
        line-height: 20px;
      }
    }
  }
  .homepage-user {
    .acc {
      display: inline-block;
      margin-left: 10px;
      line-height: 20px;
      div {
        margin: 0px;
        font-size: 13px;
        display: block;
      }
    }
  }
  > .el-main {
    > .el-card {
      margin-bottom: 10px;
    }
  }
  > .el-aside {
    overflow: visible;
    padding-bottom: 10px;

    .el-card {
      margin-bottom: 10px;
    }

    .list {
      padding-bottom: 10px;
      .main {
        > .el-link {
          float: left;
          clear: both;
        }
      }
    }
  }
}
</style>